import React from 'react'

const REGEX_FOR_NEW_LINES = /(\r|\n|\\r?\\n)/g

export function Html({ content, isRichText }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content?.replace(REGEX_FOR_NEW_LINES, isRichText ? '' : '<br>'),
      }}
    />
  )
}
